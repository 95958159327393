@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;
  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.password {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */
.bottomWrapperText {
  composes: marketplaceModalBottomWrapperText from global;
}

.recoveryLink {
  composes: marketplaceModalHelperLink from global;
}

.recoveryLinkInfo {
  composes: marketplaceModalHelperText from global;
}

/* New styles for show password functionality */
.passwordInputWrapper {
  position: relative;
}

.showPasswordIcon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 1.2rem;
  color: #888;
}
